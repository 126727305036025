import Information from "../../../../src/components/Information/index";
import Accordion from "../../../../src/components/Accordion/index";
import Tag from "../../../../src/components/Tag/index";
import Amazon from "../../../../src/components/Amazon/index";
import * as React from 'react';
export default {
  Information,
  Accordion,
  Tag,
  Amazon,
  React
};