import React from "react"

import "./tag.scss"

const Tag = ({ className, month }) => {
 
  return (
    
    <div className={"tag " + className}>
      <p className="lead">Este livro foi recebido no clube de assinatura TAG Inéditos, na caixinha do mês {month}. </p>
      <a href="https://taglivros.com/associe-se/escolha-sua-caixinha?codigo_indicacao=FLAAHIAE" target="_blank">Clique aqui para assinar a TAG</a>
      <p>Você recebe todo mês um livro e um mimo na sua casa! Assinando com o link você ganha desconto na sua primeira caixinha.</p>

    </div>
  )
}

export default Tag
