import React, { useState } from "react"

import "./accordion.scss"

const Accordion = ({ title, text }) => {

    const [isActive, setIsActive] = useState(false);


    function handleClick(e) {
        e.preventDefault()
        setIsActive(!isActive)
    }


    return (
        <div className="accordion">
            <button className={(isActive ? "active " : " ") + "accordion-btn"} onClick={handleClick}>{title}</button>
            <div className={(isActive ? "active " : " ") + "panel"}>
                <p>{text}</p>
            </div>
        </div>
    )
}
export default Accordion
