import React from "react"

import "./amazon.scss"

const Amazon = ({ className, link }) => {
 
  return (
    
    <div className={"amazon " + className}>
      <p className="lead">Se interessou pelo livro? </p>
      <a href={link} target="_blank">Clique aqui compre direto no site da Amazon</a>
      <p>O blog recebe uma comissão pela sua compra e isso ajuda à mantê-lo ativo.</p>

    </div>
  )
}

export default Amazon
