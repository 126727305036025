import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ThankYou from "../ThankYou"

import "./newsletter.scss"

const Newsletter = ({ className }) => {
  const [isSubscribed, setSubscribed] = useState(false)
  const [email, setEmail] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        setSubscribed(true)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleChange = event => {
    setEmail(event.target.value)
  }

  return (
    <div className={"newsletter " + className}>
      <p className={` ${isSubscribed ? "d-none" : "lead"} `}>
        {" "}
        Se increva na newsletter e acompanhe o blog por e-mail ✨
      </p>
      <form
        onSubmit={handleSubmit}
        className={` ${isSubscribed ? "d-none" : ""} `}
      >
        <label htmlFor="email">
          Digite o seu e-mail abaixo
          <span className="d-sm-none">, prometo não enviar spam.</span>
        </label>
        <input type="email" name="email" onChange={handleChange} placeholder="exemplo@email.com" required />
        <button type="submit">Cadastrar</button>
      </form>

      {isSubscribed && <ThankYou />}
    </div>
  )
}

export default Newsletter
