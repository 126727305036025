import React from "react"
import { Link } from "gatsby"

import "./information.scss"

const Information = ({ author, isFemale, format, language, amazonLink, isTag }) => {
    const authorLabel = (isFemale) ? "Autora" : "Autor"
    

    return (
        <ul className="information">
            <li><strong>{authorLabel}</strong>: {author}</li>
            <li><strong>Formato</strong>: {format}</li>
            <li><strong>Idioma</strong>: {language}</li>
            <li><strong>Onde comprar</strong>: <a href={amazonLink} target="_blank">Amazon</a> {(isTag) ? <a href="#">recebido pela Tag Literários (clique para assinar)</a> : ""}</li>
        </ul>
    )
}

export default Information
